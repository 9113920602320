import {type} from "../abstract/AbstractModel.js";

/**
 * Inject field "dayOfWeek" as a number between 0-6
 * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
 *
 * @mixin
 * @property dayOfWeek {Number} Day of the week Sunday - Saturday : 0 - 6
 */
const DayOfWeekMixin = (Base) =>
    class extends Base {
        dayOfWeek = {
            [type]: Number,
            required: true,
            default: () => {
                return new Date().getDay();
            },
            insertOnly: true,
            defaultOnly: true
        };
    };

export default DayOfWeekMixin;
