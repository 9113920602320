import CmsBaseClass from "@/helpers/cms/CmsBaseClass.js";
import CmsLanguageProxy from "@/helpers/editor/blocks/CmsLanguageProxy.js";
import CmsMediaQuery from "@/helpers/editor/blocks/CmsMediaQuery.js";
import NetworkV2 from "../../../../vue-components/helpers/NetworkV2.js";
import store from "@/store.js"

/**
 * A block is made of several primitive components such as text, button, icon, image...
 * @abstract
 */
export default class CmsComponent extends CmsBaseClass {
    get allowBinding() {
        return false;
    }
    get allowAdvanced() {
        return false;
    }
    get allowMediaQuery() {
        return false;
    }
    get ignoredFields() {
        return [`mediaProxy`, `languageProxy`];
    }
    get languageProxyFields() {
        return [];
    }

    constructor(data) {
        super(data);

        // object to hold mobile, kiosk and tablet variants
        this.mediaQuery = new CmsMediaQuery(this.mediaQuery);

        // The Media Proxy will use the component values or the one from mediaQuery with the device and preview
        this.mediaProxy = this.mediaQuery.createProxy(this, `compMediaProxy`);
        this.languageProxy = CmsLanguageProxy.createProxy(this);

        if (!data) {
            this.active = true;
            this.expanded = true; // editor only
            this.required = false;
            this.spacing = 10; // margin between previous block

            this.useBinding = false;
            this.itemField = null;
        }
    }

    applyChanges(language) {

    }

    isVisible() {
        if (!this.active) {
            return false;
        } else if (!this.mediaQuery) {
            // this should never happen
            return true;
        } else if (store.state.displayMode === `kiosk`) {
            if (this.isWheelchairMode) {
                return this.mediaQuery.wheelchair.visible && this.mediaQuery.kiosk.visible;
            } else {
                return this.mediaQuery.kiosk.visible;
            }
        } else if (store.state.displayMode === `tablet`) {
            return this.mediaQuery.tablet.visible;
        } else if (store.state.displayMode === `mobile`) {
            return this.mediaQuery.mobile.visible;
        } else {
            return this.mediaQuery.desktop.visible;
        }
    }

    enableBinding() {
        this.useBinding = true;
    }

    async translate(from, to) {
        this.applyChanges(from);

        if (!this.text || !this.active) {
            return;
        }

        try {
            const resp = await NetworkV2.post(`/translate`,
                {
                    text: this.text[from],
                    from: from,
                    to: to
                },
                {system_api: true});

            this.text[to] = resp.data;
        } catch(e) {
            console.error(e);
        }
    }

    /**
     * To provide refactoring compatibility only
     * @param oldField {string} old key (ex: height))
     * @param newField {string} new key (ex: size)
     */
    renameField(oldField, newField) {
        if (this.hasOwnProperty(oldField) && !this.hasOwnProperty(newField)) {
            this[newField] = this[oldField];
            delete this[oldField];
            const devices = [`mobile`, `tablet`, `kiosk`];
            for (let device of devices) {
                if (this.mediaQuery[device].hasOwnProperty(oldField) && !this.mediaQuery[device].hasOwnProperty(newField)) {
                    this.mediaQuery[device][newField] = this.mediaQuery[device][oldField];
                    delete this.mediaQuery[device][oldField];
                }
            }
        }
    }
}
