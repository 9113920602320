import Model, {type} from "../../abstract/Model.js";
import DateCreationEditionMixin from "../../mixins/DateCreationEditionMixin.js";
import DayOfWeekMixin from "../../mixins/DayOfWeekMixin.js";

/**
 * @class
 * @extends AbstractModel
 * @augments DateCreationEditionMixin
 * @augments DayOfWeekMixin
 */
export default class CmsAnalyticsSession extends DayOfWeekMixin(DateCreationEditionMixin(Model)) {
    static collection = `cms_analytics_sessions`;

    static indexes = [{"actions.type": 1}];

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true,
        index: 1
    }

    weekDay = {
        [type]: String,
        required: true,
        default: () => {
            const dayIndex = new Date().getDay();
            return [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`].at(dayIndex);
        },
        insertOnly: true,
        defaultOnly: true
    }

    websiteId = {
        [type]: String,
        index: 1
    }

    /**
     * Identifies which site or device sent the traffic. ID of the source used to create session, equivalent of Google utm_source
     * @type {{}} [qrCodeId]|[playerId]|[iframeParentUrl]|google|facebook|linkedin ex: utm_source=google
     */
    source = {
        [type]: String,
        index: 1
    }

    /**
     * Identifies what type of link or device was used when creating the session, equivalent of Google utm_medium
     * @type {{}} default|kiosk|qr-code|iframe|rdv|website|ads|social ex: utm_medium=ppc
     */
    medium = {
        [type]: String,
        default: `default`,
        required: true,
        index: 1
    }

    /**
     * @type {{}} player|desktop|tablet|mobile
     */
    device = {
        [type]: String
    }

    actions = {
        [type]: Array,
        required: false,
        default: [],
        pushOnly: true
    }

    searches = {
        [type]: Array,
        required: false
    }

    pages = {
        [type]: Array,
        required: false
    }

    /**
     * Google Standard UTM
     * https://en.wikipedia.org/wiki/UTM_parameters
     * @type {Object}
     * @property {string} utm_source Identifies which site or device sent the traffic. ID of the source used to create session, equivalent of Google utm_source
     * [qrCodeId]|[playerId]|[iframeParentUrl]|google|facebook|linkedin
     * ex: utm_source=google
     * @property {string} utm_medium Identifies what type of link or device was used when creating the session, equivalent of Google utm_medium
     * default|kiosk|qr-code|iframe|rdv|website|ads|social
     * ex: utm_medium=ppc
     * @property {string} utm_campaign Identifies a specific product promotion or strategic campaign
     * ex: utm_campaign=spring_sale
     * @property {string} utm_term Identifies search terms
     * ex: utm_term=running+shoes
     * @property {string} utm_content Identifies what specifically was clicked to bring the user to the site, such as a banner ad or a text link. It is often used for A/B testing and content-targeted ads.
     * ex: utm_content=logolink or utm_content=textlink
     */
    utms = {
        [type]: Object,
        default: () => {}
    }

    constructor() {
        super(...arguments);
    }

    getPublicFields() {
        return {
            id: this.id,
            websiteId: this.websiteId,
            dealerid: this.dealerid,
            dateCreation: this.dateCreation
        }
    }
}
