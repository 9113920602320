import CmsBaseClass from "@/helpers/cms/CmsBaseClass.js";
import CmsMediaQuery from "@/helpers/editor/blocks/CmsMediaQuery.js";
import Vue from "vue";
import {tr} from "../../../vue-components/helpers/i18n.js";
import store from "@/store.js";

export default class CmsLink extends CmsBaseClass {
    get ignoredFields() {
        return [`mediaProxy`];
    }

    static get linkTypes() {
        return [
            { value: `page`, glyph: `fa-file-text`, label: `Page` },
            { value: `item`, label: `CMS Item|Élément du CMS` },
            { value: `item-type`, label: `Closest Item Type|Type d'élément` },
            { value: `tel`, glyph: `fa-phone`, label: `Phone|Téléphone` },
            { value: `mailto`, glyph: `fa-envelope`, label: `Email|Courriel` },
            { value: `file`, glyph: `fa-file`, label: `File or image|Fichier ou image` },
            { value: `external`, glyph: `fa-link`, label: `External Link|Lien externe` },
            // Special Actions
            { value: `back`, glyph: `fa-arrow-left`, label: `Back|Retour` },
            { value: `next`, glyph: `fa-arrow-right`, label: `Next|Suivant` },
            { value: `language`, glyph: `fa-language`, label: `Language|Langue`, name: {en: `FR`, fr: `EN`} },
            { value: `collapse`, glyph: `fa-bars`, label: `Mobile Menu|Menu mobile`, name: {en: `Menu`, fr: `Menu`} },
            { value: `offline`, glyph: `fa-arrow-rotate-right`, label: `Offline Refresh|Rafraîchir hors ligne`, name: {en: `Offline`, fr: `Hors ligne`} },
            { value: `wheelchair`, glyph: `fa-wheelchair`, label: `Adjust Interface|Ajuster l'interface` }
        ];
    }
    static get socialLinkTypes() {
        return [
            { value: `email`, label: `Email|Courriel`, title: { en: `Contact Us`, fr: `Contactez-nous` }, placeholder: `Enter your email address|Entrez votre adresse courriel`, prefix: ``, glyph: `fa-envelope` },
            { value: `tel`, label: `Phone|Téléphone`, title: { en: `Call Us`, fr: `Appelez-nous` }, placeholder: `Enter your phone number|Entrez votre numéro de téléphone`, prefix: ``, glyph: `fa-phone` },
            { value: `facebook`, label: `Facebook`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `facebook.com/`, glyph: `fab fa-facebook` },
            { value: `instagram`, label: `Instagram`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `instagram.com/`, glyph: `fab fa-instagram` },
            { value: `linkedin`, label: `Linkedin`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `linkedin.com/`, glyph: `fab fa-linkedin` },
            { value: `pinterest`, label: `Pinterest`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `pinterest.com/`, glyph: `fab fa-pinterest` },
            { value: `snapchat`, label: `Snapchat`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `snapchat.com/add/`, glyph: `fab fa-snapchat` },
            { value: `tiktok`, label: `TikTok`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `tiktok.com/`, glyph: `fab fa-tiktok` },
            { value: `twitter`, label: `X (Twitter)`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `twitter.com/`, glyph: `fab fa-twitter` },
            { value: `vimeo`, label: `Vimeo`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `vimeo.com/`, glyph: `fab fa-vimeo` },
            { value: `yelp`, label: `Yelp`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `yelp.com/biz/`, glyph: `fab fa-yelp` },
            { value: `youtube`, label: `Youtube`, title: { en: `Follow Us`, fr: `Suivez-nous` }, placeholder: `username|compte`, prefix: `youtube.com/`, glyph: `fab fa-youtube` }
        ];
    }

    constructor(data) {
        super(data);

        // optimization to avoid adding 5-6 variables to every link unused 95% of the time
        if (this.mediaQuery) {
            this.initMediaQuery();
        }

        if (!data) {
            this.glyph = null;
            this.name = `Link`;
            this.href = null;
            this.type = `external`;
            this.openInNewTab = true;
            this.value = null;
            this.customize = false;
            this.thumbnail = null;
            this.action = false;
            this.customId = null;
        } else if (typeof data === `string`) {
            this.to = data;
            this.type = `router`;
        }
        if (this.cypressId) {
            this.customId = this.cypressId;
            delete this.cypressId;
        }

        this.updateLink();
    }

    initMediaQuery() {
        // object to hold mobile, kiosk and tablet variants
        Vue.set(this, `mediaQuery`, new CmsMediaQuery(this.mediaQuery));

        // The Media Proxy will use the component values or the one from mediaQuery with the device and preview
        Vue.set(this, `mediaProxy`, this.mediaQuery.createProxy(this));
    }

    updateLink() {
        if (this.type !== `page` && this.type !== `router`) {
            this.to = null;
        }
        this.thumbnail = null;
        this.action = false;
        const type = this.getType();
        switch (this.type) {
            case `page`:
                // eslint-disable-next-line no-case-declarations
                const page = this.getPage();
                if (page) {
                    if (!this.customize) {
                        this.glyph = page.glyph;
                        this.name = page.name;
                    }
                    this.href = null;
                    this.to = this.getPageTo(page);
                } else {
                    console.error(`Missing page for link ${this.name}`);
                }
                break;
            case `mailto`:
            case `tel`:
                if (!this.customize) {
                    this.name = this.value;
                }
                this.href = `${this.type}:${this.value}`;
                break;
            case `item`:
                if (!this.customize) {
                    this.name = this.value.name;
                }
                this.href = null;
                this.to = this.getItemTo(this.getItem());
                break;
            case `file`:
                if (!this.customize) {
                    this.name = this.value.name;
                }
                this.href = this.value.href;
                this.thumbnail = this.value.thumbnail;
                break;
            case `next`:
            case `back`:
            case `collapse`:
            case `offline`:
            case `language`:
            case `wheelchair`:
                if (!this.customize) {
                    this.name = type.name || type.label;
                    this.glyph = type.glyph;
                }
                this.action = true;
                break;
            case `router`:
                // do nothing here
                break;
            default:
                this.href = this.value;
                break;
        }
    }

    getType() {
        return CmsLink.linkTypes.find(t => t.value === this.type);
    }

    setPage(page) {
        this.type = `page`;
        this.value = {id: page.id};
        this.updateLink();
    }

    setItemId(itemId) {
        const item = store.state.cms.items.find(i => i.id === itemId);
        if (!item) {
            return;
        }
        this.type = `item`;
        this.value = {id: item.id, name: item.name};
        this.updateLink();
    }

    getPage() {
        return store.state.website.pages.find(p => p.id === this.value.id);
    }

    getPageTo(page) {
        let permalink = tr(page.permalink);
        if (permalink.startsWith(`/`)) {
            return permalink;
        } else {
            return `/${permalink}`;
        }
    }

    getItem() {
        return store.state.cms.items.find(i => i.id === this.value.id);
    }

    getItemTo(item) {
        if (!item) {
            return null;
        }
        return item.getViewLink();
    }

    // getHref() {
    //     if (this.type === `page`) {
    //         return null;
    //     }
    //     return this.href;
    // }
    //
    // getTo() {
    //     if (this.type === `page`) {
    //         const page = this.getPage();
    //         if (page) {
    //             let permalink = tr(page.permalink);
    //             if (permalink.startsWith(`/`)) {
    //                 return permalink;
    //             } else {
    //                 return `/${permalink}`;
    //             }
    //         } else {
    //             console.error(`Missing page for link ${this.name}`);
    //             return null;
    //         }
    //     }
    //     return null;
    // }
}
