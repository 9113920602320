/* eslint-disable */
// Otherwise throws invalid errors on dynamic import

import Vue from 'vue';
import VueRouter from 'vue-router';
import Network from "../vue-components/helpers/Network.js";
import NetworkV2 from "../vue-components/helpers/NetworkV2.js";
import store from "./store.js";

Vue.use(VueRouter);

function ifAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
        next();
    } else {
        let token = new URLSearchParams(window.location.search).get(`token`);
        if (token) {
            Network.setToken(token);
            NetworkV2.setToken(token);
        }

        store.dispatch(`getCurrentUser`)
            .then(() => {
                if (store.getters.isAuthenticated) {
                    next();
                } else {
                    window.location.href = window.env.VUE_APP_URLS__LOGIN + `/?redirect_url=` + encodeURIComponent(window.location.href);
                }
            });
    }
}

function initPreview(to, from, next) {
    let websiteId = to.params.websiteId;
    if (websiteId) {
        store.dispatch(`loadWebsite`, {id: websiteId})
            .then(() => {
                return next(`/`);
            });
    } else {
        next();
    }
}

function authenticateWithWebsite(to, from, next) {
    ifAuthenticated(to, from, () => {
        if (store.getters.currentWebsite) {
            return next();
        }

        let websiteId = to.params.websiteId || to.query.websiteId;
        if (websiteId) {
            localStorage.setItem(`websiteId`, websiteId);
        } else {
            websiteId = localStorage.getItem(`websiteId`);
        }
        store.dispatch(`loadWebsite`, {id: websiteId})
            .then(() => {
                next();
            });
    });
}

function authenticateNoWebsite(to, from, next) {
    ifAuthenticated(to, from, () => {
        store.state.applicationReady = true;
        return next();
    });
}

function loadPage(to, from, next) {
    store.state.editMode = false;
    store.dispatch(`loadPage`, to)
        .then(() => {
            if (store.getters.currentPage) {
                next();
            } else {
                next();
            }
        });
}

function loadBackend(to, from, next) {
    const domain = to.params.domain;
    if (domain) {
        // to avoid conflicts
        localStorage.removeItem(`websiteId`);
    }

    store.state.editMode = false;
    store.dispatch(`loadWebsite`, {domain: domain})
        .then(() => {
            if (store.state.website) {
                const homePage = store.state.website.getHomePage();
                window.location.href = `${window.env.VUE_APP_URLS__CMS}/cms/editor/website/${store.state.website.id}/${homePage.id}`;
                return;
            }

            next();
        });
}

const routes = {
    editor: [
        {
            path: `/cms/editor/website/creation/:dealerid`,
            name: `iframe-website-creation`,
            component: () => import('./views/iframe-website-creation.vue'),
            beforeEnter: authenticateNoWebsite
        },
        {
            path: `/cms/editor/website/:websiteId/pages/creation`,
            name: `iframe-page-creation`,
            component: () => import('./views/iframe-page-creation.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/cms/editor/website/:websiteId/settings`,
            name: `iframe-website-settings`,
            component: () => import('./views/iframe-website-settings.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/cms/editor/website/:websiteId/:pageId`,
            name: `web-editor`,
            component: () => import('./views/web-editor.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/cms/preview/website/:websiteId/:pageId`,
            name: `web-preview`,
            component: () => import('./views/web-preview.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/preview/map/:websiteId/:pageId`,
            name: `map-preview`,
            component: () => import('./views/cms-page-map.vue'),
            beforeEnter: authenticateWithWebsite
        },
        {
            path: `/init-preview/:websiteId`,
            name: `init-preview`,
            beforeEnter: initPreview
        },
        {
            path: `/test`,
            redirect(to) {
                return `/init-preview/66466eecb4a254c27842c53a`;
            }
        }
    ],
    client: [
        {
            path: `/error`,
            name: `error`,
            component: () => import('./views/default-error-page.vue')
        },
        {
            path: `/cms-admin/:domain?`,
            name: `redirect-backend`,
            component: () => import('./views/default-error-page.vue'),
            beforeEnter: loadBackend
        },
        {
            path: `*`,
            name: `cms-page-selector`,
            component: () => import('./views/cms-page-selector.vue'),
            beforeEnter: loadPage
        }
    ]
};

const router = new VueRouter({
    mode: `history`,
    routes: [...routes.editor, ...routes.client]
});

export default router;
