export default class NetworkV2 {
    static token = null;

    static setToken(token) {
        this.token = token;

        if (typeof localStorage !== `undefined`) {
            localStorage.setItem(`token`, token);
        }
    }

    static getToken() {
        if (this.token) {
            return this.token;
        }

        if (typeof localStorage !== `undefined`) {
            return localStorage.getItem(`token`);
        }

        return null;
    }

    static async removeToken() {
        this.token = null;

        if (typeof localStorage !== `undefined`) {
            localStorage.removeItem(`token`);
        }
    }

    static async get(url, params = {}, options = {}) {
        return this.request(url, `GET`, params, options);
    }

    static async post(url, params = {}, options = {}) {
        return this.request(url, `POST`, params, options);
    }

    static async put(url, params = {}, options = {}) {
        return this.request(url, `PUT`, params, options);
    }

    static async patch(url, params = {}, options = {}) {
        return this.request(url, `PATCH`, params, options);
    }

    static async delete(url, params = {}, options = {}) {
        return this.request(url, `DELETE`, params, options);
    }

    static async websocketConnect(url) {
        const token = this.getToken();

        let websocketBaseUrl = ``;
        if (typeof window !== `undefined`) {
            const websocketProtocol = window.location.protocol === `https:` ? `wss` : `ws`;

            if (window.env.VUE_APP_API_URL) {
                if (window.location.hostname.indexOf(`192.168.`) >= 0 || window.location.hostname.indexOf(`10.0.`) >= 0) {
                    const apiPort = /:(\d{2,4})/g.exec(window.env.VUE_APP_API_URL)[1];
                    websocketBaseUrl = `${websocketProtocol}://${window.location.hostname}:${apiPort}`;
                } else {
                    websocketBaseUrl = window.env.VUE_APP_API_URL.replace(`https`, websocketProtocol);
                }
            } else {
                websocketBaseUrl = `${websocketProtocol}://${window.location.host}`;
            }
        }

        if (token) {
            return new WebSocket(`${websocketBaseUrl}${url}?token=${token}`);
        } else {
            return new WebSocket(`${websocketBaseUrl}${url}`);
        }
    }

    static async request(url, method, params = {}, options = {}) {
        if (!options) {
            options = {};
        }

        if (!params) {
            params = {};
        }

        let baseUrl = ``;
        if (typeof window !== `undefined`) {
            if (window.env.VUE_APP_API_URL) {
                if (window.location.hostname.startsWith(`192.168.`) || window.location.hostname.startsWith(`10.0.`)) {
                    const apiPort = /:(\d{2,4})/g.exec(window.env.VUE_APP_API_URL)[1];
                    baseUrl = `${window.location.protocol}//${window.location.hostname}:${apiPort}`;
                } else {
                    baseUrl = window.env.VUE_APP_API_URL;
                }
            }

            if (options.eye_intelligence_api) {
                baseUrl = window.env.VUE_APP_URLS__EYEINTELLIGENCE_API;
            } else if (options.system_api) {
                baseUrl = window.env.VUE_APP_URLS__SYSTEM_API;
            } else if (options.food_force_api) {
                baseUrl = window.env.VUE_APP_URLS__FOOD_FORCE_API;
            } else if (options.licensing_api) {
                baseUrl = window.env.VUE_APP_URLS__LICENSING;
            }
        }

        if (options.external) {
            baseUrl = ``;
        } else if (options.baseUrl) {
            baseUrl = options.baseUrl;
        }

        const requestURL = new URL(baseUrl + url);

        if (typeof window !== `undefined`) {
            const currentURL = new URL(window.location.href);
            if (localStorage.hasOwnProperty(`enable_debug`)) {
                requestURL.searchParams.set(`enable_debug`, localStorage.getItem(`enable_debug`));
            } else if (currentURL.searchParams.has(`enable_debug`)) {
                requestURL.searchParams.set(`enable_debug`, currentURL.searchParams.get(`enable_debug`));
            }
        }

        if (params.progressCallback) {
            throw new Error(`Error progressCallback is a Network option, not a Network param (url: ${requestURL})`);
        }

        const abortController = new AbortController();
        let fetchOptions = {
            method: method,
            credential: `include`,
            headers: {
                "Content-Type": options.contentType || `application/json`
            },
            signal: abortController.signal
        };

        const token = this.getToken();
        if (token) {
            fetchOptions.headers.Authorization = `Bearer ${token}`;
        }

        if (method === `GET`) {
            for (const key in params) {
                requestURL.searchParams.set(key, params[key]);
            }
        } else {
            fetchOptions.body = JSON.stringify(params);
        }

        if (options.onUploadProgress) {
            options.onUploadProgress(50);
        }

        if (options.process_files) {
            const formData = new FormData();
            for (const key in params) {
                if (params[key] !== null && typeof params[key] === `object` && params[key].constructor !== File) {
                    formData.append(key, JSON.stringify(params[key]));
                } else {
                    formData.append(key, params[key]);
                }
            }
            fetchOptions.body = formData;
            fetchOptions.headers[`Content-Type`] = `multipart/form-data`;
        }

        this.#dispatchEvent(`fetch-start`);

        let response;
        const timeout = options.disableTimeout ? 0 : (options.timeout || 2 * 60 * 1000);
        if (timeout) {
            const timeoutPromise = new Promise(resolve => {
                setTimeout(() => {
                    resolve({
                        ok: false,
                        status: 408,
                        statusText: `Request timeout`
                    });

                    abortController.abort();
                }, timeout)
            })

            response = await Promise.race([fetch(requestURL, fetchOptions), timeoutPromise]);
        } else {
            response = await fetch(requestURL, fetchOptions);
        }

        if (!response.ok) {
            return this.#handleErrorResponse(response);
        }

        response.data = await this.#readResponseData(response, requestURL, options);

        if (response.status === 202 && response.data.id) {
            let finished = false;
            const start = Date.now();
            while (!finished) {
                const progressResponse = await fetch(`${baseUrl}/progress/${response.data.id}`);
                const progressResponseData = await progressResponse.json();
                if (progressResponseData.finished) {
                    finished = true;

                    if (progressResponseData.error) {
                        throw new Error(progressResponseData.error);
                    } else {
                        response.data = progressResponseData.data;
                    }
                } else {
                    if (options.progressCallback) {
                        options.progressCallback(progressResponseData.progress);
                    }

                    if (timeout && Date.now() - start >= timeout) {
                        throw new Error(`408 Request timeout`);
                    } else {
                        await new Promise(resolve => setTimeout(resolve, options.progressRefreshInterval || 2000));
                    }
                }
            }
        }

        return response;
    }

    static async #readResponseData(response, url, options) {
        const reader = response.body.getReader();

        const contentLength = +response.headers.get(`Content-Length`);
        let receivedLength = 0;
        let chunks = [];
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const {done, value} = await reader.read();

            if (done) {
                const payload = {url: url.toString(), received: receivedLength, total: contentLength, percent: 100, finished: true};
                this.#dispatchEvent(`fetch-finished`, payload);
                break;
            }

            chunks.push(value);
            receivedLength += value.length;

            const payload = {url: url.toString(), received: receivedLength, total: contentLength, percent: Math.round((receivedLength * 100) / contentLength), finished: false};
            this.#dispatchEvent(`fetch-progress`, payload);

            if (options.onDownloadProgress) {
                options.onDownloadProgress(payload);
            }
        }

        let chunksAll = new Uint8Array(receivedLength);
        let position = 0;
        for (const chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
        }

        if (options.responseType) {
            switch (options.responseType) {
                case `arraybuffer`:
                    return chunksAll.buffer;
                case `bytes`:
                    return chunksAll;
                case `formData`:
                    return response.formData();
                case `stream`:
                    return response.arraybuffer();
                case `blob`:
                    return new Blob([chunksAll]);
                case `text`:
                    return new TextDecoder(`utf-8`).decode(chunksAll);
                default:
                    return JSON.parse(new TextDecoder(`utf-8`).decode(chunksAll));
            }
        } else {
            const responseContentType = response.headers.get(`content-type`);
            if (!responseContentType) {
                return null;
            } else if (responseContentType.includes(`/json`)) {
                return JSON.parse(new TextDecoder(`utf-8`).decode(chunksAll));
            } else if (responseContentType.includes(`text/`)) {
                return new TextDecoder(`utf-8`).decode(chunksAll);
            } else if (responseContentType.includes(`multipart/form-data`)) {
                return response.formData();
            } else {
                return new Blob([chunksAll]);
            }
        }
    }

    // eslint-disable-next-line no-dupe-class-members
    static #dispatchEvent(eventName, payload = {}) {
        if (typeof window !== `undefined`) {
            const event = new CustomEvent(eventName, {detail: payload});
            window.dispatchEvent(event);
        }
    }

    // eslint-disable-next-line no-dupe-class-members
    static async #handleErrorResponse(response) {
        if (!response) {
            throw new Error(`No response`);
        }

        if (response.status === 401) {
            this.#dispatchEvent(`fetch-unauthorized`);
        }

        if (response.headers && response.headers.hasOwnProperty(`x-renew-token`)) {
            this.setToken(response.headers[`x-renew-token`]);
        }

        let data = await response.text();
        try {
            data = JSON.parse(data);
            // eslint-disable-next-line no-empty
        } catch {
        }

        let errorText;
        if (data && data.stack) {
            if (typeof data === `string`) {
                errorText = data;
            } else if (data.stack) {
                errorText = data.stack;
            }
        }

        if (data.stack) {
            errorText = data.stack;
        }

        console.error(errorText || data);

        const isLocal = window.location.host.substring(0, 10) === `localhost:` || window.location.host.substring(0, 8) === `192.168.` || window.location.host.substring(0, 5) === `10.0.`;
        const isDev = window.location.host.substring(0, 4) === `dev.`;

        switch (response.status) {
            case 500:
                this.#dispatchEvent(`fetch-error`, {
                    title: `errors.network_error`,
                    text: (isLocal || isDev) ? errorText : `errors.network_error_message`
                });
                break;
            case 403:
                this.#dispatchEvent(`fetch-error`, {
                    title: `errors.access_denied`,
                    text: `errors.access_denied_message`
                });
                break;
            case 408:
                this.#dispatchEvent(`fetch-error`, {
                    title: `errors.request_timeout`,
                    text: `errors.request_timeout_message`
                });
                break;
        }

        throw new Error(errorText);
    }
}
