import {type} from "../abstract/Model.js";

/**
 * Inject fields "dayCreation" and "dateEdition" as Dates
 * @mixin
 * @property dateCreation {Date} When the item was created
 * @property dateEdition {Date} When the item was last modified
 */
const DateCreationEditionMixin = (Base) =>
    class extends Base {
        dateCreation = {
            [type]: Date,
            required: true,
            default: () => new Date(),
            defaultOnly: true,
            insertOnly: true,
            index: -1
        }

        dateEdition = {
            [type]: Date,
            required: true,
            default: () => new Date(),
            defaultOnly: true
        }
    };

export default DateCreationEditionMixin;
