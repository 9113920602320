import Network from "@/../vue-components/helpers/Network.js";

export default class ClientSideORMProvider {
    static getUrl(model, options) {
        if (!options.url) {
            throw new Error(`Missing url for ${model.constructor.name} request.`);
        }

        return options.url;
    }

    static async findById(model, id, options = {}) {
        throw new Error(`Cannot do findById client-side`);
    }

    static async findOne(model, filter, options = {}) {
        throw new Error(`Cannot do findOne client-side`);
    }

    static async find(model, filter, options = {}) {
        throw new Error(`Cannot do find client-side`);
    }

    static async save(model, operations, options = {}) {
        throw new Error(`Cannot do save client-side`);
    }
}
