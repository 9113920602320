import TypeFactory from "../types/TypeFactory.js";

globalThis.F_TRIM = `trim`;
globalThis.F_LOWER = `lower`;

// Never export or expose these symbols outside of this class, unless you understand deeply the whole code and what you are doing
const _fields = Symbol(`_fields`);
const _options = Symbol(`_options`);
const _fieldsProperties = Symbol(`_fieldsProperties`);
const _trackedDefaults = Symbol(`_trackedDefaults`);
const _id = Symbol(`_id`);

const _save = Symbol(`_save`);
const _update = Symbol(`_update`);

export const type = Symbol(`type`);

const DEBUG = {WARN: `WARN`, PROXY: `PROXY`, METHOD: `METHOD`, INTERNAL_METHOD: `INTERNAL_METHOD`, STATIC_METHOD: `STATIC_METHOD`};

// Change this to enable some debug logs:
const debugList = {[DEBUG.WARN]: false, [DEBUG.PROXY]: false, [DEBUG.METHOD]: false, [DEBUG.INTERNAL_METHOD]: false, [DEBUG.STATIC_METHOD]: false};

/**
 * @class
 * @template T
 * @abstract
 */
export default class AbstractModel {
    // used to filter (the upsert in save method for example)
    static primaryKey = [`id`];
    static extractFields = {name: 1};

    static _isProxied = Symbol(`_isProxied`);

    [_id] = null; // model id (mandatory _id in MongoDB even if it's not a primary key)
    [_fields] = {}; // true model fields are saved here (except for id/_id)
    [_fieldsProperties] = {}; // field definitions set during the subclass definition (type, required, etc.)
    [_trackedDefaults] = {}; // list of defaults values still unchanged

    // options added to the constructor 2nd argument, defaults are here
    [_options] = {
        replaceOnly: false,
        updateOnly: false
    };

    // used in case of save() call, replace value with an upsert
    [_save] = {
        $set: {},
        $setOnInsert: {},
        $inc: {},
        $mul: {},
        $unset: {}
    };

    // used in case of update() call, can do partial update
    [_update] = {
        $set: {},
        $setOnInsert: {},
        $inc: {},
        $mul: {},
        $push: {},
        $pop: {},
        $unset: {}
    };

    get id() {
        return this[_id];
    }

    constructor(fields = {}, options = {}) {
        this._debug(DEBUG.METHOD, `constructor`, options);

        // Save options with default options if not present
        this[_options] = {
            ...this[_options],
            ...options
        }

        // Save fields, clone to prevent Proxy of Proxy issues
        this[_fields] = this._cloneObject(fields);

        // By default, we put everything in $set (useful for unknown fields, known fields will be reset in $defineField a little later in case of insertOnly)
        this[_save].$set = this._cloneObject(fields);
        this[_update].$set = this._cloneObject(fields);

        // remove id or _id which is separated from _fields
        if (this[_fields].id || this[_fields]._id) {
            this[_id] = this[_fields].id || this[_fields]._id;
            delete this[_fields]._id;
            delete this[_fields].id;
            delete this[_save].$set._id;
            delete this[_save].$set.id;
            delete this[_update].$set._id;
            delete this[_update].$set.id;
        }

        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
        return new Proxy(this, {
            get(self, key, receiver) {
                self._debug(DEBUG.PROXY, `get`, key);

                if (key === `id`) {
                    return self[_id];
                }

                if (self[_fields].hasOwnProperty(key)) {
                    return self[_fields][key];
                }

                return Reflect.get(...arguments);
            },
            set(self, key, value) {
                self._debug(DEBUG.PROXY, `set`, key, value);

                if (typeof key === `symbol` || key[0] === `_`) {
                    return Reflect.set(...arguments);
                } else if (typeof value === `object` && value?.hasOwnProperty(type)) { // only for fields definition inside Class, some JS implementation does not use the defineProperty trap
                    self.$defineField(key, value);
                    return true;
                } else {
                    return self.$set(key, value);
                }
            },
            // trap instance field definition (fields defined outside the constructor of the subclass)
            defineProperty(self, key, descriptor) {
                self._debug(DEBUG.PROXY, `defineProperty`, key, descriptor);

                if (typeof descriptor.value === `object` && descriptor.value?.hasOwnProperty(type)) {
                    self.$defineField(key, descriptor.value);
                    return true;
                }
                return Reflect.defineProperty(self, key, descriptor);
            },
            deleteProperty(self, key) {
                self._debug(DEBUG.PROXY, `deleteProperty`, key);

                self.$unset(key);
                return Reflect.deleteProperty(...arguments);
            },
            has(self, key) {
                self._debug(DEBUG.PROXY, `has`, key);

                // Reject all symbols and protected keys (_) from outside
                if (typeof key === `symbol` || key[0] === `_`) {
                    console.warn(`Model: getting inside Proxy.has trap with a key symbol or protected key should never happen`);
                    return false;
                }

                if (key === `id`) {
                    return self[_id];
                }

                if (self[_fields].hasOwnProperty(key)) {
                    return true;
                }

                return Reflect.has(...arguments);
            },
            ownKeys(self) {
                self._debug(DEBUG.PROXY, `ownKeys`);

                if (self[_id]) {
                    return Object.keys({id: self[_id], ...self[_fields]});
                }
                return Object.keys(self[_fields]);
            },
            getOwnPropertyDescriptor(self, key) {
                self._debug(DEBUG.PROXY, `getOwnPropertyDescriptor`, key);

                if ((typeof key === `symbol` || key[0] === `_`) && self.hasOwnProperty(key)) {
                    return {
                        writable: true,
                        enumerable: false,
                        configurable: true
                    };
                } else if (key === `id` && self[_id]) {
                    return {
                        writable: true,
                        enumerable: true,
                        configurable: true
                    };
                }

                return Object.getOwnPropertyDescriptor(self[_fields], key);
            }
        });
    }

    /**
     * Get field by key. Key can have a dot format to go deep inside objects.
     * @param key {String} key can have dot format for deep get: `user.dateOfBirth.day` for example
     * @returns {*}
     */
    $get(key) {
        this._debug(DEBUG.METHOD, `$get`, key);

        if (typeof key === `string` && key.includes(`.`)) {
            const keys = key.split(`.`);

            let obj = this;
            for (const key of keys) {
                if (!obj.hasOwnProperty(key)) {
                    return undefined;
                }
                obj = obj[key];
            }

            return obj;
        } else if (this[_fields].hasOwnProperty(key)) {
            return this[_fields][key];
        } else {
            return undefined;
        }
    }

    /**
     * This should be called only in class attribute definition.
     * Note: The field definitions are called AFTER the constructor of the subclass, so we have potential this[_fields] defined in constructor that are not validated or reactive yet
     * @param key {String}
     * @param fieldProperties {Object}
     */
    $defineField(key, fieldProperties) {
        this._debug(DEBUG.METHOD, `$defineField`, key, fieldProperties);

        // Model class validation

        if (typeof fieldProperties !== `object`) {
            this._throwError(`$defineField`, `Cannot define field "${key}": fieldProperties is not an object.`);
        }
        if (!fieldProperties?.hasOwnProperty(type)) {
            this._throwError(`$defineField`, `Cannot define field "${key}": Missing Model.type Symbol property in fieldProperties argument.`);
        }
        if (fieldProperties.validation && typeof fieldProperties.validation !== `function`) {
            this._throwError(`$defineField`, `Field property "validation" must be a function for field "${key}"`);
        }

        this[_fieldsProperties][key] = fieldProperties;

        if (fieldProperties.defaultOnly) {
            if (!this._hasDefaultValue(key)) {
                this._throwError(`$defineField`, `Cannot find default for "${key}" with defaultOnly: true`);
            } else if (fieldProperties.incrementOnly || fieldProperties.multiplyOnly || fieldProperties.pushOnly) {
                this._throwError(`$defineField`, `defaultOnly is incompatible with incrementOnly, multiplyOnly, pushOnly`);
            }
        }

        if (this[_fields].hasOwnProperty(key) && typeof this[_fields][key] === `undefined`) {
            this._throwError(`$defineField`, `Cannot initialize field "${key}" with undefined in constructor. If you want to unset a value, use delete operator or model.$unset('${key}')`);
        }

        let value;
        // if this field has not been defined in the constructor, we put the default value if any and stop here
        if (!this[_fields].hasOwnProperty(key)) {
            // if field has a default value
            if (this._hasDefaultValue(key)) {
                value = this._getDefaultValue(key);
                this[_fields][key] = this.$reactive(key, value);

                // setOnInsert to not erase a DB value not fetched
                this._addDBOperation(`$setOnInsert`, key, value);
                this[_trackedDefaults][key] = true;
            }
            return;
        }

        // here we have a value defined in the constructor
        value = this[_fields][key];

        // defaultOnly
        if (fieldProperties.defaultOnly) {
            value = this._getDefaultValue(key);
            this[_fields][key] = this.$reactive(key, value);

            if (fieldProperties.insertOnly) {
                this._addDBOperation(`$setOnInsert`, key, value);
            } else {
                this._addDBOperation(`$set`, key, value);
            }
            this[_trackedDefaults][key] = true;
            return;
        }

        // Type validation
        const fieldType = fieldProperties[type]?.name;
        try {
            value = TypeFactory.new(fieldType, value);
        } catch (e) {
            this._throwError(`$defineField`, `Cannot assign type "${typeof value}" to "${key}" with type "${fieldType}"`);
        }

        // we apply potential flags
        value = this._applyFlags(value, fieldProperties.flags);

        // incrementOnly, multiplyOnly, pushOnly are not authorized during field initialization
        if (fieldProperties.incrementOnly || fieldProperties.multiplyOnly || fieldProperties.pushOnly) {
            this[_fields][key] = this.$reactive(key, value);
            this._removeDBOperation(key);
            this._debug(DEBUG.WARN, `$defineField`, `Key "${key}" (incrementOnly: ${fieldProperties.incrementOnly}, multiplyOnly: ${fieldProperties.multiplyOnly}, pushOnly: ${fieldProperties.pushOnly}) ignored during constructor, use $increment, $multiply or $push instead.`);
            return;
        }

        // User defined validation function
        if (fieldProperties.validation && !fieldProperties.validation(value)) {
            this._throwError(`_defineField`, `Cannot assign "${value}" to field "${key}", validation function returned false`);
        }

        // Fill the correct operator
        if (fieldProperties.insertOnly) {
            this._addDBOperation(`$setOnInsert`, key, value);
        } else {
            this._addDBOperation(`$set`, key, value);
        }

        this[_fields][key] = this.$reactive(key, value);
    }

    /**
     * This function is called automatically when you write something like model.myCustomField = `newValue`
     * @param key {String} Name of the field (for example: myCustomField)
     * @param value {*} Value to be set to the field but not yet set
     * @param options {Object}
     * @returns {boolean} Returns true if the value has successfully be assigned to the field, should never return false but throw an error if there is an issue
     */
    $set(key, value, options = {}) {
        this._debug(DEBUG.METHOD, `$set`, key);

        // Special treatment for id
        if (key === `_id` || key === `id`) {
            this[_id] = value;
            return true;
        }

        if (typeof key !== `string`) {
            this._throwError(`$set`, `key must be a string, got ${typeof key}`);
        }

        if (typeof value === `undefined`) {
            this._throwError(`$set`, `Cannot set field "${key}" with undefined. If you want to unset a value, use delete operator or model.$unset('${key}')`);
        }

        // key can have dot format but in this case no validation is done
        if (key.includes(`.`)) {
            const keys = key.split(`.`);
            const lastKey = keys.pop();

            let obj = this[_fields];
            for (const key of keys) {
                if (obj.hasOwnProperty(key)) {
                    obj = obj[key];
                } else {
                    obj[key] = {}
                    obj = obj[key];
                }
            }

            obj[lastKey] = value;

            const rootKey = keys.shift();
            this._addDBSaveOperation(`$set`, rootKey, this._cloneObject(this[_fields][rootKey]));
            this._addDBUpdateOperation(`$set`, key, value);

            return true;
        }

        // if we don't know this field, we just add it
        if (!this[_fieldsProperties].hasOwnProperty(key)) {
            this[_fields][key] = this.$reactive(key, value);
            this._addDBOperation(`$set`, key, value);
            return true;
        }

        // From here we know the field properties are defined in this[$fieldsProperties][key]
        const fieldProperties = this[_fieldsProperties][key];

        this[_trackedDefaults][key] = false;

        if (!options.force) {
            value = this._validateFieldValue(key, value);

            // incrementOnly, multiplyOnly, pushOnly
            if (fieldProperties.incrementOnly) {
                // necessary to be able to write model.insertOnlyProp += 1; or model.insertOnlyProp++; or model.insertOnlyProp--;
                const oldValue = this[_fields][key] || 0;
                this.$increment(key, value - oldValue);
                return true;
            } else if (fieldProperties.multiplyOnly) {
                // necessary to be able to write model.insertOnlyProp *= 2; or model.insertOnlyProp /= 2;
                const oldValue = this[_fields][key] || 1;
                this.$multiply(key, value / oldValue);
                return true;
            } else if (fieldProperties.pushOnly) {
                // Do not authorize model.arrayProp = value to be equivalent to model.arrayProp.push(value), you can use model.$set(`arrayProp`, arrayValue, {force: true}) instead
                this._throwError(`$set`, `Cannot set "${key}" array property with pushOnly: true`);
            }
        }

        // Fill the correct operator
        if (fieldProperties.insertOnly) {
            this._addDBOperation(`$setOnInsert`, key, value);
        } else {
            this._addDBOperation(`$set`, key, value);
        }

        // Adding the value to the model
        this[_fields][key] = this.$reactive(key, value);

        return true;
    }

    $setAllDefaultsOnly() {
        this._debug(DEBUG.METHOD, `$setAllDefaultsOnly`);

        for (const key in this[_fieldsProperties]) {
            const fieldProperties = this[_fieldsProperties][key];
            if (!fieldProperties.defaultOnly) {
                continue;
            }

            let value;
            if (this[_fields][key] && fieldProperties.insertOnly) {
                value = this._cloneObject(this[_fields][key]);
            } else {
                value = this._getDefaultValue(key);
            }

            this[_fields][key] = this.$reactive(key, value);

            if (fieldProperties.insertOnly) {
                this._addDBOperation(`$setOnInsert`, key, value);
            } else {
                this._addDBOperation(`$set`, key, value);
            }

            this[_trackedDefaults][key] = true;
        }
    }

    /**
     * Make a field reactive. Only do something if it's an Array or an Object.
     * Does not do anything if in creationMode
     * @param key {String}
     * @param value {*}
     * @returns {*}
     */
    $reactive(key, value) {
        this._debug(DEBUG.METHOD, `$reactive`, key);

        const self = this;

        if (Array.isArray(value)) {
            if (value[AbstractModel._isProxied]) {
                return value;
            }

            return new Proxy(this._cloneObject(value), {
                get(target, prop) {
                    if (prop === AbstractModel._isProxied) {
                        return true;
                    }

                    const traceMethods = [`push`, `unshift`, `pop`, `shift`];
                    if (traceMethods.includes(prop)) {
                        return item => {
                            switch (prop) {
                                case `push`:
                                    self.$push(key, item);
                                    return true;
                                case `unshift`:
                                    self.$unshift(key, item);
                                    return true;
                                case `pop`:
                                    self.$pop(key);
                                    return true;
                                case `shift`:
                                    self.$shift(key);
                                    return true;
                            }
                        }
                    }

                    return target[prop];
                }
            });
        } else if (value?.constructor?.name === `Object`) {
            if (value[AbstractModel._isProxied]) {
                return value;
            }

            return new Proxy(this._cloneObject(value), {
                get(target, k) { // k to avoid shadowing key
                    if (k === AbstractModel._isProxied) {
                        return true;
                    }

                    return Reflect.get(...arguments);
                },
                set(target, prop, value) {
                    const result = Reflect.set(...arguments);

                    self._addDBOperation(`$set`, key, self[_fields][key]);

                    return result;
                }
            });
        } else {
            return value;
        }
    }

    /**
     * Set all keys from the arguments. Equivalent of Object.assign(model, obj).
     * @param obj {Object}
     * @param force Skip all validation
     */
    $setAll(obj, force = false) {
        this._debug(DEBUG.METHOD, `$setAll`);

        for (const key in obj) {
            if (typeof obj[key] === `undefined`) {
                continue;
            }

            this.$set(key, obj[key], {force: force});
        }
    }

    /**
     * Increment key with the value;
     * @param key {String}
     * @param value {Number}
     */
    $increment(key, value = 1) {
        this._debug(DEBUG.METHOD, `$increment`, key);

        const oldValue = this.$get(key) || 0;
        let newValue = oldValue + value;
        newValue = this._validateFieldValue(key, newValue);
        this[_fields][key] = this.$reactive(key, newValue);

        const newIncrement = (this[_update].$inc[key] || 0) + value;
        this._addDBOperation(`$inc`, key, newIncrement);
    }

    /**
     * Increment all obj keys with their associated value;
     * @param obj {Object}
     */
    $incrementAll(obj) {
        this._debug(DEBUG.METHOD, `$incrementAll`);

        for (const key in obj) {
            this.$increment(key, obj[key]);
        }
    }

    /**
     * Multiply key with the value;
     * @param key {String}
     * @param value {Number}
     */
    $multiply(key, value) {
        this._debug(DEBUG.METHOD, `$multiply`, key);

        const oldValue = this.$get(key) || 1;
        let newValue = oldValue * value;
        newValue = this._validateFieldValue(key, newValue);
        this[_fields][key] = newValue;

        const newIncrement = (this[_update].$mul[key] || 1) * value;
        this._addDBOperation(`$mul`, key, newIncrement);
    }

    /**
     * Multiply all obj keys with their associated value;
     * @param obj {Object}
     */
    $multiplyAll(obj) {
        this._debug(DEBUG.METHOD, `$multiplyAll`);

        for (const key in obj) {
            this.$multiply(key, obj[key]);
        }
    }

    /**
     * Push new value to an array.
     * @param key {String}
     * @param value {*}
     */
    $push(key, value) {
        this._debug(DEBUG.METHOD, `$push`, key);

        const oldValue = this.$get(key) || [];
        let newValue = [...oldValue, value];
        newValue = this._validateFieldValue(key, newValue);
        this[_fields][key] = this.$reactive(key, newValue);
        this._addDBSaveOperation(`$set`, key, newValue);

        if (typeof this[_update].$pop[key] !== `undefined` || typeof this[_update].$push[key]?.$position !== `undefined`) {
            this[_options].replaceOnly = true;
        }

        if (this[_options].replaceOnly) {
            return;
        }

        let operationValue = {$each: []};
        if (typeof this[_update].$push[key] === `undefined`) {
            if (typeof this[_update].$set[key] === `undefined`) {
                operationValue.$each = [value];
            } else {
                operationValue.$each = [...this[_update].$set[key], value];
                delete this[_update].$set[key];
            }
            this._addDBUpdateOperation(`$push`, key, operationValue);
        } else {
            operationValue.$each = [...this[_update].$push[key].$each, value];
            this._addDBUpdateOperation(`$push`, key, operationValue);
        }
    }

    /**
     * Push several values at once;
     * @param key {String}
     * @param arr {Array}
     */
    $pushAll(key, arr) {
        this._debug(DEBUG.METHOD, `$pushAll`, key);

        for (const value of arr) {
            this.$push(key, value);
        }
    }

    /**
     * Unshift new value to an array.
     * @param key {String}
     * @param value {*}
     */
    $unshift(key, value) {
        this._debug(DEBUG.METHOD, `$unshift`, key);

        const oldValue = this.$get(key) || [];
        let newValue = [value, ...oldValue];
        newValue = this._validateFieldValue(key, newValue);
        this[_fields][key] = this.$reactive(key, newValue);
        this._addDBSaveOperation(`$set`, key, newValue);

        if (typeof this[_update].$pop[key] !== `undefined` || (typeof this[_update].$push[key] !== `undefined` && typeof this[_update].$push[key].$position === `undefined`)) {
            this[_options].replaceOnly = true;
        }

        if (this[_options].replaceOnly) {
            return;
        }

        let operationValue = {$each: [], $position: 0};

        if (typeof this[_update].$push[key] === `undefined`) {
            if (typeof this[_update].$set[key] === `undefined`) {
                operationValue.$each = [value];
            } else {
                operationValue.$each = [value, ...this[_update].$set[key]];
                delete this[_update].$set[key];
            }
            this._addDBUpdateOperation(`$push`, key, operationValue);
        } else {
            operationValue.$each = [value, ...this[_update].$push[key].$each];
            this._addDBUpdateOperation(`$push`, key, operationValue);
        }
    }

    /**
     * Pop key of an array.
     * @param key {String}
     */
    $pop(key) {
        this._debug(DEBUG.METHOD, `$pop`, key);

        const oldValue = this.$get(key) || [];
        let newValue;
        if (oldValue.length === 0) {
            newValue = [];
        } else {
            newValue = oldValue.slice(0, oldValue.length - 1);
        }

        newValue = this._validateFieldValue(key, newValue);
        this[_fields][key] = this.$reactive(key, newValue);
        this._addDBSaveOperation(`$set`, key, newValue);

        if (typeof this[_update].$push[key] !== `undefined` || this[_update].$pop[key] < 0) {
            this[_options].replaceOnly = true;
        }

        if (this[_options].replaceOnly) {
            return;
        }

        const oldPopValue = this[_update].$pop[key] || 0;
        this._addDBUpdateOperation(`$pop`, key, oldPopValue + 1);
    }

    /**
     * Shift key of an array.
     * @param key {String}
     */
    $shift(key) {
        this._debug(DEBUG.METHOD, `$shift`, key);

        const oldValue = this.$get(key) || [];
        let newValue;
        if (oldValue.length === 0) {
            newValue = [];
        } else {
            newValue = oldValue.slice(1, oldValue.length);
        }

        newValue = this._validateFieldValue(key, newValue);
        this[_fields][key] = this.$reactive(key, newValue);
        this._addDBSaveOperation(`$set`, key, newValue);

        if (typeof this[_update].$push[key] !== `undefined` || this[_update].$pop[key] > 0) {
            this[_options].replaceOnly = true;
        }

        if (this[_options].replaceOnly) {
            return;
        }

        const oldPopValue = this[_update].$pop[key] || 0;
        this._addDBUpdateOperation(`$pop`, key, oldPopValue - 1);
    }

    /**
     * Equivalent of "delete model.myCustomfield". It unsets the key in Database too after save().
     * @param key {String}
     */
    $unset(key) {
        this._debug(DEBUG.METHOD, `$unset`, key);

        delete this[_fields][key];
        this._addDBOperation(`$unset`, key, ``);
    }

    /**
     * Unset all keys from the model and database after save()
     * @param keys {Array|Object}
     */
    $unsetAll(keys) {
        this._debug(DEBUG.METHOD, `$unsetAll`, keys);

        if (!keys) {
            return;
        }

        if (typeof keys === `object`) {
            keys = Object.keys(keys);
        }

        for (const key of keys) {
            this.$unset(key);
        }
    }

    /**
     * Delete all operations without canceling changes made to the object. Only affect the save and update methods which will do nothing if called directly after this call.
     */
    $deleteOperations() {
        this._debug(DEBUG.METHOD, `$deleteOperations`);

        this.$deleteSaveOperations();
        this.$deleteUpdateOperations();
    }

    /**
     * Delete all save operations without canceling changes made to the object. Only affect the save method which will do nothing if called directly after this call.
     */
    $deleteSaveOperations() {
        this._debug(DEBUG.METHOD, `$deleteSaveOperations`);

        for (const $operator in this[_save]) {
            this[_save][$operator] = {};
        }
    }

    /**
     * Delete all update operations without canceling changes made to the object. Only affect the update method which will do nothing if called directly after this call.
     */
    $deleteUpdateOperations() {
        this._debug(DEBUG.METHOD, `$deleteUpdateOperations`);

        for (const $operator in this[_update]) {
            this[_update][$operator] = {};
        }

        this[_options].replaceOnly = false;
    }

    async save(options = {}) {
        this._debug(DEBUG.METHOD, `save`, options);

        this.constructor._checkModelValidity();
        if (!options.update) {
            this._checkValidity();
        }

        if (options.update && this[_options].replaceOnly) {
            this._throwError(`save`, `Cannot update model in replaceOnly mode, verify your operations on arrays are permitted.`);
        } else if (!options.update && this[_options].updateOnly) {
            this._throwError(`save`, `Cannot save model in updateOnly mode. Use .update() instead.`);
        }

        const operations = options.update ? this._getUpdateQuery() : this._getSaveQuery();
        const result = await globalThis.eyeInORMProvider.save(this, operations, options);

        if (options.refresh) {
            this.$setAll(result, true);
        }

        this.$deleteOperations();
        this.$setAllDefaultsOnly();
        this[_options].replaceOnly = false;
        this[_options].updateOnly = false;
    }

    /**
     * Do an upsert replacing document and then do a find by primaryKey to retrieve exact document matching database
     *
     * @param options {Object|String}
     * @returns {Promise<void>}
     */
    async saveAndRefresh(options = {}) {
        this._debug(DEBUG.METHOD, `saveAndRefresh`, options);

        options.refresh = true;
        return this.save(options);
    }

    /**
     * Do an upsert to partial update a document
     * @param options {Object|String}
     * @returns {Promise<void>}
     */
    async update(options = {}) {
        this._debug(DEBUG.METHOD, `update`, options);

        options.update = true;
        return this.save(options);
    }

    /**
     * Do an upsert to partial update a document and then do a find by primaryKey to retrieve exact document matching database
     *
     * @param options {Object|String}
     * @returns {Promise<void>}
     */
    async updateAndRefresh(options = {}) {
        this._debug(DEBUG.METHOD, `updateAndRefresh`, options);

        options.refresh = true;
        return this.update(options);
    }

    /**
     * Delete document in database by primaryKey
     * @param options {Object|String}
     * @returns {Promise<void>}
     */
    async delete(options = {}) {
        this._debug(DEBUG.METHOD, `delete`, options);

        await globalThis.eyeInORMProvider.delete(this, options);
    }

    /**
     * Returns version of this model without fields with default value that have not been changed
     * @returns Object
     */
    toBody() {
        let result = {};
        for (const key in this[_fields]) {
            if (this[_trackedDefaults][key]) {
                continue;
            }

            result[key] = this[_fields][key];
        }

        return result;
    }

    /**
     * Clone model with a new separate instance
     *
     * @returns {AbstractModel}
     */
    clone() {
        this._debug(DEBUG.METHOD, `clone`);

        const model = new this.constructor({}, this[_options]);
        model.$setAll(this[_fields], true);
        model.$deleteOperations();
        model.$setAllDefaultsOnly();

        return model;
    }

    _getSaveQuery() {
        this._debug(DEBUG.INTERNAL_METHOD, `_getSaveQuery`);
        return this[_save];
    }

    _getUpdateQuery() {
        this._debug(DEBUG.INTERNAL_METHOD, `_getUpdateQuery`);
        return this[_update];
    }

    getSaveQuery() {
        this._debug(DEBUG.METHOD, `getSaveQuery`);
        return this._cloneObject(this._getSaveQuery());
    }

    getUpdateQuery() {
        this._debug(DEBUG.METHOD, `getUpdateQuery`);
        return this._cloneObject(this._getUpdateQuery());
    }

    getOptions() {
        this._debug(DEBUG.METHOD, `getOptions`);
        return this._cloneObject(this[_options]);
    }

    getFieldsProperties() {
        this._debug(DEBUG.METHOD, `getFieldsProperties`);
        return this._cloneObject(this[_fieldsProperties]);
    }

    getAuthUser() {
        this._debug(DEBUG.METHOD, `getAuthUser`);
        return globalThis.eyeInORMProvider.getAuthUser(this[_options]);
    }

    /*
     * PROTECTED Helpers
     */

    _validateFieldValue(key, value) {
        this._debug(DEBUG.INTERNAL_METHOD, `_validateFieldValue`, key);

        if (!this[_fieldsProperties].hasOwnProperty(key)) {
            return value;
        }

        const fieldProperties = this[_fieldsProperties][key];

        // defaultOnly means value is equal to the default value no matter what is set by the user
        // No need to do it here since it's already done during the construction of the object
        if (fieldProperties.defaultOnly) {
            this._throwError(`_validateFieldValue`, `Cannot set field "${key}" with defaultOnly: true`);
        }

        // Type validation
        const fieldType = fieldProperties[type]?.name;
        try {
            value = TypeFactory.new(fieldType, value);
        } catch (e) {
            this._throwError(`_validateFieldValue`, `Cannot assign type "${typeof value}" to "${key}" with type "${fieldType}"`);
        }

        // Flags
        value = this._applyFlags(value, fieldProperties.flags);

        // User defined validation function
        if (fieldProperties.validation && !fieldProperties.validation(value)) {
            this._throwError(`_validateFieldValue`, `Cannot assign "${value}" to field "${key}", validation function returned false`);
        }

        return value;
    }

    _hasDefaultValue(key) {
        this._debug(DEBUG.INTERNAL_METHOD, `_hasDefaultValue`, key);

        return typeof this[_fieldsProperties][key].default !== `undefined`;
    }

    _getDefaultValue(key) {
        this._debug(DEBUG.INTERNAL_METHOD, `_getDefaultValue`, key);

        if (!this._hasDefaultValue(key)) {
            return undefined;
        }

        let defaultValue = undefined;
        if (typeof this[_fieldsProperties][key].default === `function`) {
            defaultValue = this[_fieldsProperties][key].default();
        } else {
            defaultValue = this[_fieldsProperties][key].default;
        }

        return defaultValue;
    }

    _checkValidity() {
        this._debug(DEBUG.INTERNAL_METHOD, `_checkValidity`);

        for (const key in this[_fieldsProperties]) {
            if (this[_fieldsProperties][key].required) {
                if (typeof this[_fields][key] === `undefined`) {
                    this._throwError(`_checkValidity`, `"${key}" is required but is undefined.`);
                } else if (!this[_fieldsProperties][key].allowEmpty && TypeFactory.isEmpty(this[_fields][key])) {
                    this._throwError(`_checkValidity`, `"${key}" is required but is empty.`);
                }
            }
        }
    }

    _cloneObject(value) {
        this._debug(DEBUG.INTERNAL_METHOD, `_cloneObject`);

        if (value instanceof Date) {
            return new Date(value);
        }

        return JSON.parse(JSON.stringify(value));
    }

    _addDBOperation($operator, key, value) {
        this._debug(DEBUG.INTERNAL_METHOD, `_addDBOperation`, $operator, key);

        this._addDBSaveOperation($operator, key, value);
        this._addDBUpdateOperation($operator, key, value);
    }

    _addDBSaveOperation($operator, key, value) {
        this._debug(DEBUG.INTERNAL_METHOD, `_addDBSaveOperation`, $operator, key);

        let rootKey = key;
        if (key.includes(`.`)) {
            rootKey = key.split(`.`).shift();
        }

        for (const $op in this[_save]) {
            if (this[_save][$op].hasOwnProperty(rootKey)) {
                delete this[_save][$op][key];
            }
        }

        this[_save][$operator][key] = value;
        this[_trackedDefaults][key] = false;
    }

    _addDBUpdateOperation($operator, key, value) {
        this._debug(DEBUG.INTERNAL_METHOD, `_addDBUpdateOperation`, $operator, key);

        let rootKey = key;
        if (key.includes(`.`)) {
            rootKey = key.split(`.`).shift();
        }

        for (const $op in this[_update]) {
            if (this[_update][$op].hasOwnProperty(rootKey)) {
                delete this[_update][$op][key];
            }
        }

        this[_update][$operator][key] = value;
        this[_trackedDefaults][key] = false;
    }

    _removeDBOperation(key) {
        this._debug(DEBUG.INTERNAL_METHOD, `_removeDBOperation`, key);

        this._removeDBSaveOperation(key);
        this._removeDBUpdateOperation(key);
    }

    _removeDBSaveOperation(key) {
        this._debug(DEBUG.INTERNAL_METHOD, `_removeDBSaveOperation`, key);

        for (const $op in this[_save]) {
            if (this[_save][$op].hasOwnProperty(key)) {
                delete this[_save][$op][key];
            }
        }
    }

    _removeDBUpdateOperation(key) {
        this._debug(DEBUG.INTERNAL_METHOD, `_removeDBUpdateOperation`, key);

        for (const $op in this[_update]) {
            if (this[_update][$op].hasOwnProperty(key)) {
                delete this[_update][$op][key];
            }
        }
    }

    _applyFlags(value, flags = []) {
        this._debug(DEBUG.INTERNAL_METHOD, `_applyFlags`, flags);

        for (const flag of flags) {
            switch (flag) {
                case F_TRIM:
                    value = value.trim();
                    break;
                case F_LOWER:
                    value = value.toLowerCase();
                    break;
            }
        }

        return value;
    }

    /**
     * Create an instance with fields populated but with  operations
     *
     * @param fields
     * @param options
     * @returns {T}
     */
    static createFromData(fields = {}, options = {}) {
        this._debug(DEBUG.STATIC_METHOD, `createFromData`, options);

        this._checkModelValidity();

        const model = new this({}, options);
        model.$setAll(fields, true);
        model.$deleteOperations();
        model.$setAllDefaultsOnly();

        return model;
    }

    populateFromData(fields = {}, options = {}) {
        this._debug(DEBUG.STATIC_METHOD, `populateFromData`, options);

        this.constructor._checkModelValidity();

        this.$setAll(fields, true);
        this.$deleteOperations();
        this.$setAllDefaultsOnly();
    }

    /**
     * Retrieves the first Model that matches with the filter query
     *
     * @param id {String} Model ID as String
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to retrieve. Ex: {name: 1, dealerid: 1}. See https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results/
     * @param [options.createIfEmpty] {Boolean}
     * @returns {Promise<T>}
     */
    static async findById(id, options = {createIfEmpty: false}) {
        this._debug(DEBUG.STATIC_METHOD, `findById`, id, options);

        const emptyModel = new this();
        const doc = await globalThis.eyeInORMProvider.findById(emptyModel, id, options);
        if (!doc) {
            return options.createIfEmpty ? new this({}, options) : null;
        }
        return this.createFromData(doc, options);
    }

    /**
     * @param id
     * @param options
     * @returns {Promise<Boolean>} Returns false if not found
     */
    async findById(id, options = {}) {
        this._debug(DEBUG.METHOD, `findById`, id, options);

        const doc = await globalThis.eyeInORMProvider.findById(this, id, options);
        if (!doc) {
            return false;
        }
        this.populateFromData(doc);
        return true;
    }

    /**
     * Retrieves the first Model that matches with the filter query
     *
     * @param filter {Object}
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to retrieve. Ex: {name: 1, dealerid: 1}. See https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results/
     * @param [options.createIfEmpty] {Boolean}
     * @returns {Promise<T>}
     */
    static async findOne(filter, options = {createIfEmpty: false}) {
        this._debug(DEBUG.STATIC_METHOD, `findOne`, options);

        const model = new this();
        const doc = await globalThis.eyeInORMProvider.findOne(model, filter, options);
        if (!doc) {
            return options.createIfEmpty ? new this({}, options) : null;
        }
        return this.createFromData(doc, options);
    }

    /**
     * Retrieves the first Model that matches with the filter query
     *
     * @param filter {Object}
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to retrieve. Ex: {name: 1, dealerid: 1}. See https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results/
     * @param [options.createIfEmpty] {Boolean}
     * @returns {Promise<T|Boolean>} Returns false if not found
     */
    async findOne(filter, options = {}) {
        this._debug(DEBUG.METHOD, `findOne`, options);

        const doc = await globalThis.eyeInORMProvider.findOne(this, filter, options);
        if (!doc) {
            return false;
        }
        this.populateFromData(doc);
    }

    /**
     * Retrieves an Array of Model that matches with the filter query
     *
     * @param filter {Object}
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to retrieve. Ex: {name: 1, dealerid: 1}. See https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results/
     * @returns {Promise<Array<T>>}
     */
    static async find(filter, options = {}) {
        this._debug(DEBUG.STATIC_METHOD, `find`, options);

        const model = new this();
        const docs = await globalThis.eyeInORMProvider.find(model, filter, options);
        return this.arrayFrom(docs, options);
    }

    /**
     * Returns an array of Model objects
     *
     * @param arr {Array}
     * @param options {Object}
     * @returns {Array<T>}
     */
    static arrayFrom(arr, options = {}) {
        this._debug(DEBUG.STATIC_METHOD, `arrayFrom`, options);

        return arr.map(doc => this.createFromData(doc, options));
    }

    /**
     * Retrieves an Array of Model that matches with the filter query.
     * Only returns the fields marked with preview = true
     *
     * @param filter {Object}
     * @param [options] {Object}
     * @param [options.sort] {Object} List of fields to sort in order. Ex: {date: 1}. See https://www.mongodb.com/docs/drivers/node/current/fundamentals/crud/read-operations/sort/
     * @param [options.projection] {Object} List of fields to extract
     * @returns {Promise<Array<T>>}
     */
    static async findDocExtracts(filter, options = {}) {
        this._debug(DEBUG.STATIC_METHOD, `findDocExtracts`, options);

        Object.assign(options, {projection: (options.projection || this.extractFields)});
        return this.find(filter, options);
    }

    /**
     * Delete many mongo operation
     *
     * @param filter
     * @param options
     * @returns {Promise<void>}
     */
    static async deleteMany(filter, options = {}) {
        this._debug(DEBUG.STATIC_METHOD, `deleteMany`, options);

        const model = new this();

        if (!options.force && (!filter || Object.keys(filter).length === 0)) {
            this._throwStaticError(`deleteMany`, `Missing filter`);
        }

        return globalThis.eyeInORMProvider.deleteMany(model, filter, options);
    }

    /**
     * Use MongoDB change streams to watch changes in the model collection
     * @param pipeline {Array} filter changes to watch see https://www.mongodb.com/docs/manual/changeStreams/#modify-change-stream-output
     * @param options {Object} see options https://www.mongodb.com/docs/manual/changeStreams/#lookup-full-document-for-update-operations
     * @param callback {Function} callback function called with each changes
     * @returns {Promise<void>}
     */
    static async watch(pipeline, options, callback) {
        this._debug(DEBUG.STATIC_METHOD, `watch`, options);

        const model = new this();
        return globalThis.eyeInORMProvider.watch(model, pipeline, options, doc => {
            callback(this.createFromData(doc));
        });
    }

    /**
     * Only watch inserts in the collection
     * @param callback {Function} callback function called with each insert
     * @returns {Promise<void>}
     */
    static async watchInserts(callback) {
        this._debug(DEBUG.STATIC_METHOD, `watchInserts`);

        const model = new this();
        await globalThis.eyeInORMProvider.watchInserts(model, doc => {
            callback(this.createFromData(doc));
        });
    }

    /**
     * Only watch updates in the collection
     * @param callback {Function} callback function called with each update for any document
     * @returns {Promise<void>}
     */
    static async watchUpdates(callback) {
        this._debug(DEBUG.STATIC_METHOD, `watchUpdates`);

        const model = new this();
        globalThis.eyeInORMProvider.watchUpdates(model, doc => {
            callback(this.createFromData(doc));
        });
    }

    /*
     * STATIC HELPERS
     */

    static _checkModelValidity() {
        this._debug(DEBUG.INTERNAL_METHOD, `_checkModelValidity`);

        if (!this.collection) {
            this._throwStaticError(`_checkModelValidity`, `Missing collection name`);
        }
    }

    /*
     * DEBUG
     */

    static _throwStaticError(methodName, errorMessage) {
        throw new Error(`[${this.name}][${methodName}()] ${errorMessage}`);
    }

    _throwError(methodName, errorMessage) {
        throw new Error(`[${this.constructor.name}][${methodName}()] ${errorMessage}`);
    }

    static _debug(level, methodName, ...args) {
        if (!debugList[level]) {
            return;
        }

        args = Array.from(args);
        for (let i = 0; i < args.length; i++) {
            if (typeof args[i] === `object`) {
                args[i] = JSON.stringify(args[i]);
            }
        }

        console.log(`(${level}) [${this.name}.${methodName}()]`, ...args);
    }

    _debug(level, methodName, ...args) {
        this.constructor._debug(level, methodName, ...args);
    }
}
